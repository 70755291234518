@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap");
body {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Jost", sans-serif;
}
p,
span,
a,
button,
div {
  font-family: "Kumbh Sans", sans-serif;
}

.btn-group {
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  gap: 24px;
  padding-left: 0px;
}
.cnt {
  padding: 0% 8%;
  /* padding-bottom: 0px; */
}
.tp {
  padding-top: 10%;
}
.bgg {
  background-color: #4aa0d0;
  padding: 30px 0px;
}
.bgg span {
  color: white;
  line-height: 32px;
}
.bgg h1 {
  font-size: 42px;
  text-align: center;
  color: white;
  margin: 0;
}
.cnt h4 {
  color: #4aa0d0;
  font-size: 32px;
  padding: 0;
  margin: 0;
}
.cnt p b {
  color: #4aa0d0;
  font-size: 22px;
}
.cnt p {
  line-height: 32px;
}
.btn-group a {
  text-decoration: none;
  display: inline-flex;
  gap: 20px;
  font-size: 20px;
  line-height: 1.2;
  color: #222e48;
  font-weight: 400;
  cursor: pointer;
  letter-spacing: inherit;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 16px 32px;
  border-radius: 35px;
  transition: all 0.7s ease-in-out;
  color: #fff;
  border: 1px solid #156a8f;
  background-color: #156a8f !important;
}

.Navbar .logo img {
  /* filter: drop-shadow(0 0 0.75rem rgb(187, 183, 159)); */
}
.Navbar {
  position: fixed;
  background-color: white;
  width: 100%;
  z-index: 111;
}

.Navbar-c {
  padding: 0 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  width: 50%;
}
.logo img {
  /* width: 65%; */
}
.btn-group a {
  background-image: linear-gradient(white, white),
    linear-gradient(#4aa0d0, #4aa0d0);
  background-size: 0 100%, auto;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.5s ease-out;
}
.btn-group a:hover {
  background-color: white;
  color: #5bb286;
  transition: 0.3s ease;
  background-size: 100% 100%, auto;
}

/* src/styles/animations.css */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideInFromBottom {
  0% {
    transform: translateY(-5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-from-left {
  opacity: 0;
}

.animate-from-right {
  opacity: 0;
}

.animate {
  opacity: 1 !important;
}

.animate-from-left.animate {
  animation: slideInFromLeft 1s forwards;
}

.animate-from-right.animate {
  animation: slideInFromRight 1s forwards;
}
.animate-from-top.animate {
  animation: slideInFromTop 1s forwards;
}

.animate-from-bottom.animate {
  animation: slideInFromBottom 1s forwards;
}
.MobileNavbar {
  display: none;
}
.Footer {
  padding: 5px 8%;
  /* background-color: #d5d9d8; */
  padding-top: 25px;
  background-color: white;
}

.Footer1 {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.Footer1 img {
  margin-top: 40px;
}
.Footer a {
  text-decoration: none;
  color: #404a60;
  font-size: 16px;
}
.Footer li {
  font-size: 18px;
}
.Footer span {
  text-align: center;
  width: 100%;
  display: block;
  margin-top: 30px;
  font-size: 18px;
}
.Footer ul {
  padding: 0;
  margin: 0;
}
.Footer ul li {
  list-style-type: none;
  cursor: pointer;
}

.svg {
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.hero_section {
  position: relative;
  padding: 0% 8%;
  padding-top: 150px;
  background-color: #06954d;
  padding-bottom: 60px;
  overflow: hidden; /* Ensure that the pseudo-element doesn't overflow the parent */
}

.hero_section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("./Component/assets/images/photos/vector.avif") no-repeat
    center center;
  background-size: cover;
  opacity: 0.3; /* Adjust the opacity to your liking */
  z-index: 1;
}
.hero-flex {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}
.hero-content {
  width: 46%;
}
.hero-images {
  width: 54%;
  display: flex;

  position: relative;
}
.hero-images div {
  width: 50%;
  text-align: center;
  position: relative;
}
.hero-images div img {
  width: 90%;
  border-radius: 50%;
  z-index: 2;
  position: relative;
}
.hero-images .left-img img {
  height: 420px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.hero-images .right-img img {
  height: 470px;
  width: 100%;
}
span {
  /* color: #5bb286; */
  font-size: 18px;
  font-weight: 600;
}
.hero_section span {
  color: white;
}
.hero_section h1 {
  font-size: 60px;
  font-weight: 900;
  line-height: 60px;
  margin: 0px;
  margin-top: 10px;
  color: white;
  letter-spacing: 2px;
}
.hero_section p {
  font-size: 20px;
}
.hero-images .absolute {
  position: absolute;
  top: -12px;
  width: 25px;
  height: 25px;
  left: 44%;
  z-index: 1;
  background-color: #eceff0;
  border: 50px solid #4aa0d0;
  border-radius: 50%;
}
.why {
  background-image: url(./Component/assets/images/photos/bg.png);
  background-repeat: no-repeat;
  background-position: top right;
}
.hero-images .absolute1 {
  position: absolute;
  bottom: -30px;
  width: 100px;
  height: 100px;
  left: 70%;
  z-index: 1;
}
.hero-images .absolute2 {
  position: absolute;
  bottom: -10px;
  width: 30px;
  height: 30px;
  left: -50px;
  z-index: 1;
}
.contact {
  background-image: url(./Component/assets/images/bg-shep-cnt.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  border: 1px solid #5cb286;
  position: relative;
  width: 80%;
  margin: 0 auto;
  border-radius: 5px;
  margin-top: 30px;
}
.contact::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(231, 230, 230, 0.8); /* Adjust color and opacity as needed */
  pointer-events: none; /* Allows clicks to pass through to underlying content */
  z-index: 1;
}
.Navbar-c .btn-group {
  padding: 18px !important;
}
.contact-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  position: relative;
}
.contact-flex div {
  width: 33%;
}
.contact-flex .contact-image {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-flex div img {
  width: 70%;
}
.contact-heading h3 {
  color: #16243e;
  font-weight: 900;
  font-size: 25px;
}

.services {
  position: relative;
  background-color: #06954d;
  padding: 30px;
  overflow: hidden; /* Ensure that the pseudo-element doesn't overflow the parent */
}

.services::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("./Component/assets/images/photos/vector.avif") no-repeat
    center center;
  background-size: cover;
  opacity: 0.3; /* Adjust the opacity to your liking */
  z-index: 1;
}
.head-content {
  padding: 2% 8%;
}
.services h4 {
  text-align: center ;
  margin-bottom: 12px;
  font-size: 32px;
  color: white;
  width: 80%;
  margin: 0 auto;
}
.services p {
  line-height: 25px !important;
  font-size: 20px;
  text-align: center;
  color: #fffbfb;
  width: 70%;
  margin: 0 auto;
  margin-top: 20px;
}
.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 120px;
  /* width: 100%; */
}

.right-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
}
.why .img-container {
  background-color: #5bb286;
  padding: 30px;
  border-radius: 12px;
}
.img-container {
  position: relative;
  width: 100%;
}
.why .text {
  position: absolute;
  color: #fff;
  background-color: #1fa36d;
  border: 5px solid #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transform: rotate(0deg);
  padding: 32px 35px;
  border-radius: 12px;
  top: 65%;
  left: -37%;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  width: 61%;
  z-index: 11;
}
.why .img2 {
  position: absolute;
  height: 220px;
  width: 200px;
  right: -6%;
  bottom: -40px;
  border: 5px solid white;
  border-radius: 12px;
}

.why .img3 {
  position: absolute;
  top: -45px;
  right: -54px;
}

.img1 {
  min-width: 100%;
  min-height: 100%;
}
.img2 {
  position: absolute;
  height: 325px;
  width: 245px;
  right: -15%;
  bottom: -20px;
  border: 5px solid white;
  border-radius: 12px;
}

.text {
  position: absolute;
  color: #fff;
  background-color: #1fa36d;
  border: 5px solid #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transform: rotate(270deg);
  padding: 32px 35px;
  border-radius: 50px;
  top: 49%;
  left: -45%;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.top {
  h5 {
    color: #5bb286;
    font-weight: 600;
    letter-spacing: 1.6px;
    line-height: 22.4px;
    text-align: left;
  }
  h4 {
    color: #4aa0d0;
    font-size: 45px;
    font-weight: 600;
    letter-spacing: -1.8px;
    line-height: 50px;
    text-align: left;
    text-justify: initial;
    margin: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0 !important;
    padding: 0;
  }
}

.paragraph {
  color: #67687a;
  line-height: 24px;
  text-align: left;
  text-justify: initial;
  margin: 0;
  font-size: 16px;
}

.icons {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 20px;
  margin-top: 20px;
}

.image {
  width: 70px;
  height: 70px;
  background-color: #5bb286;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  img {
    width: 40px;
    height: 40px;
    /* filter: invert(1); */
    padding: 10px;
  }
}
.image:hover {
  background-color: #156a8f;
  transition: 0.3s ease;
}

.titles {
  display: flex;
  flex-direction: column;
  gap: 10;
  h3 {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.5px;
    line-height: 25.2px;
    text-align: left;
    margin: 0;
    color: #4aa0d0;
  }
  p {
    line-height: 24px;
    text-align: left;
    margin: 0;
    color: #67687a;
    font-size: 16px;
  }
}
.why .image {
  background-color: transparent;
}
.why .image {
  img {
    width: 39px;
    height: 35px;
    /* filter: invert(1); */
  }
}

.icons-wrap {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}
.main {
  padding: 2% 8%;
}
.why {
  gap: 20px;
}
.left-container {
  width: 50%;
}
.left-container .img1 {
  width: 100%;
  border-radius: 12px;
}
.why .icons-wrap {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

@media (max-width: 768px) {
  .Navbar {
    display: none;
  }
  .mb  .left-container .img1{
    display: none;
  }
  .mb .btn-group {
    display: none;
  }
  .Footer1 {
    flex-direction: column;
    padding-bottom: 10px;
  }
  Footer {
    padding: 40px 7% !important;
    background-color: #d5d9d8;
  }
  .mobile {
    display: block;
  }
  .mobile img {
    width: 100%;
  }
  .desktop {
    display: none;
  }
  .Footer span {
    padding-bottom: 90px;
    font-size: 15px;
  }
  .bottomFixed {
    background-color: #4aa0d0;
    text-align: center;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 1111;
    padding: 10px 0px;
  }
  .topFixed {
    text-align: center;
    padding: 10px;
  }
  /* Root variables for easy customization */
  :root {
    --btn-border: 1px solid rgba(255, 255, 255, 0.2);
    --btn-bg: #1fa36d; /* Background color for the button */
    --btn-shadow: 1px 1px 25px 10px rgba(255, 255, 255, 1);
    --btn-text-color: #f4f4f4;
    --shine-color: #79aabe; /* Prominent shine */
    --shine-degree: -45deg;
    --shine-duration: 5s; /* Duration of the shine animation */
  }

  /* Styling for the button */
  .bottomFixed a {
    text-decoration: none;
    color: var(--btn-text-color);
    font-size: 25px;
    font-weight: 600;
    background-color: var(--btn-bg);
    width: 90%;
    display: block;
    margin: 0 auto;
    height: 90%;
    padding: 15px 0;
    border-radius: 10px;
    position: relative; /* Necessary for ::before positioning */
    overflow: hidden; /* Hide the overflow for the shine effect */
    border: var(--btn-border);
  }

  /* Shining animation */
  .bottomFixed a::before {
    content: "";
    position: absolute;
    top: 0;
    left: -150%; /* Start off screen */
    width: 100%;
    height: 100%;
    background: linear-gradient(
      var(--shine-degree),
      transparent 40%,
      /* Start the transparent part */ var(--shine-color) 50%,
      /* Narrower white shine */ transparent 75% /* End the transparent part */
    );
    animation: shine var(--shine-duration) linear infinite;
  }
  .lp111 {
    position: relative;
    overflow: hidden;
  }
  /* Keyframes for the shine animation */
  @keyframes shine {
    0% {
      left: -150%; /* Start off screen */
    }
    50% {
      left: 150%; /* Move across the button */
    }
    50.01% {
      left: -150%; /* Restart immediately */
    }
    100% {
      left: 150%; /* Move across the button */
    }
  }

  .MobileNavbar {
    display: block;
  }
  .col-2 {
    width: 100%;
  }
  span {
    font-size: 20px;
  }
  .Hero_Bg h1 {
    margin-top: 10px;
    margin-bottom: 8px;
    font-size: 30px;
  }
  p {
    font-size: 20px;
  }
  .btn-group a {
    padding: 12px 4px;
    font-size: 20px;
  }
  .WhyChooseUs span {
    font-size: 22px;
  }
  .btn-group {
    padding: 30px 0px !important;
    width: 100%;
  }
  .btn-group a {
    width: 100%;
  }
  .fix,
  .WhyChooseUs,
  .Services {
    padding: 20px 0px;
  }
  h2 {
    margin-top: 10px;
    margin-bottom: 8px;
    font-size: 28px;
  }
  .WhyChooseUs p {
    margin-bottom: 0px;
  }
  .card h4 {
    font-size: 25px;
  }
  .card p {
    font-size: 20px;
  }
  .Services .card {
    padding: 32px;
  }
  .mee .card {
    flex-direction: column;
    padding: 20px;
  }
  .HowWorks .card {
    flex-direction: column;
  }
  .row {
    gap: 0rem;
  }
  .Services .card {
    flex-direction: column;
  }
  .Services .card_container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }
  .lp111 .Hero_Bg h1 {
    color: #156a8f !important;
  }
  .hero-flex {
    flex-direction: column;
  }
  .hero-content {
    width: 100%;
    text-align: center;
  }
  .hero_section {
    padding-top: 40px;
  }
  .hero_section h1 {
    font-size: 29px;
    line-height: 43px;
    letter-spacing: 0px;
  }
  .hero-images .left-img {
    display: none;
  }
  .hero-images .absolute {
    display: none;
  }
  .hero-images {
    width: 100%;
  }
  .hero-images div {
    width: 100%;
  }
  .hero-images .absolute1 {
    position: absolute;
    bottom: -30px;
    width: 70px;
    height: 70px;
    left: 70%;
    z-index: 1;
  }

  .right-container {
    width: 100%;
  }
  .left-container {
    width: 100%;
  }
  .img-container {
    position: relative;
    width: auto;
  }
  .main {
    flex-direction: column-reverse;
    justify-content: center;
    gap: 12px;
  }
  .why {
    flex-direction: column;
  }
  .contact {
    padding: 30px;
  }
  .contact-flex {
    flex-direction: column;
  }
  .head-content {
    padding: 0%;
  }
  .contact-flex div {
    width: 100%;
  }
  .why .img3 {
    display: none;
  }
  .icons {
    flex-direction: column;
    align-items: center;
  }

  .img2 {
    display: none;
  }
  .text {
    display: none;
  }
  .services h4 {
    text-align: left;
    margin-bottom: 12px;
    font-size: 26px;
    width: 100%;
  }
  .services p {
    line-height: 25px !important;
    font-size: 20px;
    text-align: left;
    width: 100%;
  }
  .top {
    h4 {
      color: #4aa0d0;
      font-size: 32px;
      line-height: 40px;
    }
  }
}
